<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import { saveActivity } from '@/helpers/game'
  import { toInt } from '@/helpers/mixHelpers'

  import Controller from '@/components/lesson/Controller.svelte'
  import EditMnemo from '@/components/lesson/EditMnemo.svelte'
  import ExampleSentencesAccordion from '@/components/lesson/ExampleSentencesAccordion.svelte'
  import FormsTable from '@/components/lesson/FormsTable.svelte'
  import HomSyn from '@/components/lesson/HomSyn.svelte'
  import LessonWordBlock from '@/components/lesson/LessonWordBlock.svelte'
  import Sentence from '@/components/lesson/Sentence.svelte'
  import Accordion from '@/components/ui/Accordion.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import Loader from '@/components/ui/Loader.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'
  import ReportButton from '@/components/ui/ReportButton.svelte'

  import { LessonWord } from '@/definitions/langoid'
  import { _ } from '@/libs/i18n'

  export let iso = '',
    group = ''

  const wordsToDisplay = 10
  let apiData: any,
    allWords: LessonWord[] = [],
    currentWord: LessonWord = {} as LessonWord,
    words: LessonWord[] = [],
    currentSentenceIndex = 0,
    wordsOffset = 0,
    currentWordIndex = 0,
    groupsCount = 0,
    lessonWords: any = {},
    exampleSentences: any = [],
    props: any = {}

  $: if (allWords.length > 0) {
    words = getWordsToDisplay(wordsOffset, wordsToDisplay, allWords)
    currentWord = words[currentWordIndex]
    groupsCount = Math.ceil(allWords.length / wordsToDisplay)
    exampleSentences = getExampleSentences(currentWord)
  }
  loadWordsExplorer()

  async function loadWordsExplorer () {
    const data: any = await fetchData('learn/wordsExplorer', { group })
    apiData = data
    lessonWords = data.words
    allWords = []
    for (const i in apiData.words) {
      allWords.push(data.words[i])
    }
    props = data.props
  }

  function getExampleSentences (currentWord: LessonWord) {
    const id = currentWord.id
    return apiData.exampleSentences[id] || []
  }

  function getWordsToDisplay (offset: number, length: number, words: LessonWord[]) {
    return words.slice(offset, length + offset)
  }

  function onClickOnWord (selected: number) {
    saveActivity(['we-word-info', '', words[currentWordIndex].id_word])
    currentWordIndex = selected
  }

  function onSentenceChange (direction: string) {
    if (direction === 'prev') {
      currentSentenceIndex--
      wordsOffset -= wordsToDisplay
    } else if (direction === 'next') {
      currentSentenceIndex++
      wordsOffset += wordsToDisplay
    } else {
      const intDirection = toInt(direction)
      wordsOffset = intDirection * wordsToDisplay
      currentSentenceIndex = intDirection
    }
    currentWordIndex = 0
  }

  const prevSentence = () => {
    if (wordsOffset >= wordsToDisplay) onSentenceChange('prev')
  }

  const nextSentence = () => {
    if (wordsOffset < allWords.length - 1 - wordsToDisplay) {
      onSentenceChange('next')
    }
  }

  const prevWord = () => {
    saveActivity(['lesson-next-word', 'prev'])
    currentWordIndex = findNextWordIndex('prev')
  }

  const nextWord = () => {
    saveActivity(['lesson-next-word', 'next'])
    const wordIndexTotal = currentSentenceIndex * wordsToDisplay + currentWordIndex
    if (wordIndexTotal < allWords.length - 1) {
      currentWordIndex = findNextWordIndex('next')
    }
  }

  function findNextWordIndex (direction: string) {
    let index = currentWordIndex
    if (direction === 'next') {
      while (index < words.length) {
        index++
        if (index === words.length && wordsOffset + wordsToDisplay < allWords.length) {
          onSentenceChange('next')
        }
        if (!words[index] || words[index].id <= 0) {
          continue
        }
        return index
      }
    } else if (direction === 'prev') {
      if (index !== 0) {
        while (index > 0) {
          index--
          if (words[index].id > 0) return index
        }
      } else if (currentSentenceIndex !== 0) {
        onSentenceChange('prev')
      }
    }
    return currentWordIndex
  }

  function onClickOnProgress (sentenceIndex: string) {
    onSentenceChange(sentenceIndex)
  }

  function handleKeydown (e: KeyboardEvent) {
    const keyPressed = e.key
    const targetEvent = e.target as HTMLElement
    if (targetEvent.classList.contains('mnemo-input')) {
      // we can change line above to make it work like this on any input[type=text]
    } else if (['ArrowLeft', 'ArrowUp', 'ArrowDown', 'ArrowRight'].includes(keyPressed)) {
      e.preventDefault()
      saveActivity(['lesson-shortcut-used', keyPressed])
      if (keyPressed === 'ArrowLeft') {
        prevWord()
      } else if (keyPressed === 'ArrowUp') {
        nextSentence()
      } else if (keyPressed === 'ArrowDown') {
        prevSentence()
      } else if (keyPressed === 'ArrowRight') {
        nextWord()
      }
    }
  }

  function onSave (e: CustomEvent) {
    const mnemo = e.detail.mnemo
    currentWord.mnemo = mnemo
    words[currentWordIndex].mnemo = mnemo
  }

  function loadWord (id: number) {
    // console.log(id)
  }

  const groups = {
    L0: 'seen',
    L1: 'starting',
    L2: 'good',
    L3: 'know'
  }
</script>
<svelte:window on:keydown={handleKeydown} />
{#if allWords.length === 0}
  <Loader />
{:else if group}
  <Breadcrumb
    breads={[
    { mobileUrl: `/${iso}/vocabulary`, text: '', url: `/${iso}` },
    { text: $_('vocabularyPage.title'), url: `/${iso}/vocabulary` },
    { text: $_('vocabularyPage.exploreWords')+ ': ' + $_('vocabularyPage.label_'+ groups[group])}
    ]}
  >
    <h2 class='heading-mobile'>{$_('vocabularyPage.exploreWords') + ': ' + $_('vocabularyPage.label_' + groups[group])}</h2>
    <div slot='pageHelperIcons' class='_breadcrumbicons'>
      {#key currentWord.id}
        <EditMnemo idWord={currentWord.id} mnemo={currentWord.mnemo || ''} on:mnemo-edited={onSave} />
      {/key}
      {#key currentWord.id}
        <ReportButton category='we_word' rel={currentWord.id} />
      {/key}
    </div>
  </Breadcrumb>
  <ProgressBar maxProgress={groupsCount} {onClickOnProgress} progress={currentSentenceIndex + 1} />
  <div class='_gap24'>
    <Sentence
      {iso}
      {lessonWords}
      {onClickOnWord}
      selected={currentWordIndex}
      type='exploreWords'
      {words}
    />
    <LessonWordBlock {currentWord} {iso} props={props[currentWord.id]} />
    <hr class='_desktopOnly' />
    <Controller
      {nextSentence}
      {nextWord}
      {prevSentence}
      {prevWord}
    />
  </div>
  {#if currentWord.mnemo}
    <Accordion show>
      <div slot='header'>Note</div>
      <div slot='content'>
        {currentWord.mnemo}
      </div>
    </Accordion>
  {/if}

  <ExampleSentencesAccordion sentences={exampleSentences} />
  <FormsTable {currentWord} templates={apiData.templates} />
  <HomSyn {currentWord} {loadWord} synonyms={apiData.homonyms?.[currentWord.id]} type='homonyms' />
  <HomSyn {currentWord} {loadWord} synonyms={apiData.synonyms?.[currentWord.id]} type='synonyms' />
  <div class='controller-background'>
    <br />
  </div>
{/if}

<style lang='scss'>
  .controller-background {
    display: none;
  }

  @media(max-width: 768px) {
    .controller-background {
      display: block;
      width: 100%;
      height: 4.8rem;
    }
  }
</style>
