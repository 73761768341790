<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'

  import { getIdFromIso } from '@/helpers/apiCall'
  import { normalizeMsg, updateMessageCounts } from '@/helpers/chatHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { initializeAndUpdateDb, updateFriendVocabulary } from '@/helpers/indexedDbHelpers'
  import { getUniqueObjectsByKey, playNotificationSound, toInt } from '@/helpers/mixHelpers'
  import { initializeSocket } from '@/helpers/socket'

  import ChatContainer from '@/components/chat/ChatContainer.svelte'
  import ChatFilters from '@/components/chat/ChatFilters.svelte'
  import Icon from '@/components/icons/Icon.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { BookmarkMessageApi, Bread, ChatFriend, ChatHistoryData, ChatMessage, SelectedWord, Vocabulary } from '@/definitions/langoid'
  import { MixedStoreData } from '@/definitions/stores'
  import { _ } from '@/libs/i18n'
  import { chatSelectedUser, menuLanguage, messagesStore, mixStore, userStorredConfig } from '@/store'

  export let iso: string,
    id: string

  const vocabulary: Record<number, Vocabulary> = {}
  let breads: Bread[] = []

  const selectedUser: number | undefined = toInt(id) > 0 ? toInt(id) : undefined,
    selectedCategory: 'bookmarks' | 'corrections' | undefined = id === 'bookmarks' || id === 'corrections' ? id : undefined
  const chatPage = selectedUser ? 'chatUser' : selectedCategory
  let lastPage = true,
    messages: ChatMessage[] = [],
    selectedWord: SelectedWord = {} as SelectedWord,
    users: Record<number, any> = {},
    userName: string = '',
    histories: ChatFriend[] = [],
    correctionMode: 'test' | 'read' = 'test',
    showFilters = false

  const getSingleUser = async (id: number) => {
    const data = await fetchData('social/profileLoad', { userId: id })
    return data.user
  }

  if (Object.keys(users).length === 0 && selectedUser !== undefined) {
    getSingleUser(selectedUser).then((user: any) => {
      users = { ...users, [selectedUser]: user }
      userName = users[selectedUser].name
      breads = [
        { mobileUrl: `/${iso}`, text: '', url: `/${iso}` },
        { text: $_('chat.users'), url: `/${iso}/chat/users` },
        { text: selectedUser ? userName : selectedCategory === 'bookmarks' ? $_('chat.bookmarks') : $_('mix.corrections') }
      ]
    })
  } else {
    breads = [
      { mobileUrl: `/${iso}`, text: '', url: `/${iso}` },
      { text: selectedCategory === 'bookmarks' ? $_('chat.bookmarks') : $_('mix.corrections') }
    ]
  }

  onMount(() => {
    userStorredConfig.setKey('id_lang_learning', getIdFromIso(iso))
    menuLanguage.set(iso)
    messages = []
    initializeAndUpdateDb()
    initializeSocket(handleSocketMessage)

    if (selectedUser) {
      chatSelectedUser.set(selectedUser)
      mixStore.update((m: MixedStoreData) => updateMessageCounts(m, selectedUser, iso))
    }

    if (selectedUser) {
      getMessages(selectedUser, true)
      updateFriendVocabulary(selectedUser)
    } else if (selectedCategory) {
      messages = []
      getMessages(selectedCategory)
    }
  })

  const unsubscribe = messagesStore.subscribe((value: ChatMessage[]) => {
    messages = value
  })
  const mutate = (data: ChatMessage[]) => {
    messagesStore.set(getUniqueObjectsByKey(data, 'id'))
  }

  // https://stackoverflow.com/questions/60948306/svelte-how-to-pass-data-back-to-svelte-component-from-javascript-file
  let _callback: (data: ChatMessage[]) => void
  const assignMessagesCallback = (callback: (data: ChatMessage[]) => void) => {
    _callback = callback // <- save a callback for future change
    callback(messages)
  }

  const isVisible = () => document.visibilityState === 'visible'
  assignMessagesCallback((data: any) => {
    const chatIsOpen = isVisible() && ($chatSelectedUser === data.sender || $chatSelectedUser === data.receiver)
    // todo - line above will not work if we get message from the same user in other language

    const { type, messageId, res } = data
    // eslint-disable-next-line no-console
    console.log('from server: ', data, $chatSelectedUser, 'open:', chatIsOpen, 'type:', type, 'IsVisible:', isVisible())
    if (type === 'sendMessage') {
      if (!chatIsOpen) {
        const sender = data.sender
        playNotificationSound()
        mixStore.update((m: MixedStoreData) => updateMessageCounts(m, sender, iso))
      } else {
        mutate([...messages, normalizeMsg(data)])
      }
    } else if (type === 'markAsRead') {
      if ($chatSelectedUser === data.userId) {
        messages.forEach((m: ChatMessage) => {
          if (m.id <= messageId) m.msg_read = 1
        })
        mutate(messages)
      }
    } else if (type === 'messageDelete') {
      mutate(messages.filter(m => m.id !== messageId))
    } else if (type === 'messageReactions') {
      messages = messages.map(m => {
        if (m.id === messageId) {
          return {
            ...m,
            reactions: data?.reactions
          }
        } else {
          return m
        }
      })
      mutate(messages)
    } else {
      const message = messages.find(m => m.id === messageId)
      if (message) {
        if (type === 'messageEdit') {
          message.edited = 'yes'
          message.posTagged = res.tagged
          message.message = res.message
        } else if (type === 'messageCorrect') {
          message.correction = res
          message.correction.correction_note = res.correctionNote
        } else if (type === 'explanationRequest' || type === 'explanationAnswer') {
          message.explanation = {
            ...res,
            answer: res.answer ? JSON.parse(res.answer) : undefined,
            question: res.question ? JSON.parse(res.question) : undefined
          }
        }
        mutate(messages)
      }
    }
  })

  const getMessages = async (selected: number | 'bookmarks' | 'corrections', isUser = false) => {
    if (isUser && selected !== 'bookmarks' && selected !== 'corrections') {
      const data: ChatHistoryData = await fetchData('chat/loadHistory', { friend: selected })
      const tmp = Object.values(data?.messages || {})
      messages = tmp.map(normalizeMsg)
      histories = data.histories
      lastPage = !!data.lastPage
      mixStore.update((m: MixedStoreData) => updateMessageCounts(m, selected, iso))
    } else {
      const endpoint = selected === 'bookmarks' ? 'chat/loadBookmarks' : 'chat/loadCorrections'
      const data: BookmarkMessageApi = await fetchData(endpoint)
      messages = data.messages.map(normalizeMsg)
      if (data.users) {
        users = { ...users, ...data.users }
      }
      lastPage = !!data.lastPage
    }
  }

  function handleSocketMessage (e: any) {
    try {
      const data = JSON.parse(e.data)
      if (_callback) _callback(data)
    } catch (error: unknown) {
      console.error('parsed content not ok', e.data, error)
    }
  }

  const showOlder = async () => {
    if (selectedCategory) {
      const endpoint = selectedCategory === 'bookmarks' ? 'chat/loadBookmarks' : 'chat/loadCorrections'
      const data: BookmarkMessageApi = await fetchData(endpoint, { cursor: messages[0]?.created_at })
      messages = data.messages.map(normalizeMsg).concat(messages)
      lastPage = !!data.lastPage
    } else if (selectedUser) {
      const data: ChatHistoryData = await fetchData('chat/loadHistory', { cursor: messages[0]?.created_at, friend: selectedUser })
      messages = Object.values(data.messages).map(normalizeMsg).concat(messages)
      lastPage = !!data.lastPage
    }
  }

  onDestroy(() => {unsubscribe()})
</script>

<Breadcrumb {breads}>
  <h2 class='heading-mobile'>
    {selectedUser ? userName : selectedCategory === 'bookmarks' ? $_('chat.bookmarks') : $_('mix.corrections')}
  </h2>
  <div slot='pageHelperIcons' class='_breadcrumbicons'>
    <FakeButton onClick={() => {showFilters = !showFilters}}>
      <span class='_icon -noBorder' class:-active={showFilters}><Icon icon='Sliders' weight='regular' /></span>
      <span class='filterHeading _mobileOnly'>{$_('forum.filters')}</span>
    </FakeButton>
    <hr class='_mobileOnly' />
    <PageInfo kebabItem pageName='chat' />
  </div>
</Breadcrumb>
<div class='chat-list'>
  {#if selectedCategory}
    <ChatFilters
      active={selectedCategory}
      {chatPage}
      {iso}
      {selectedCategory}
      bind:correctionMode
      bind:showFilters
    />
  {/if}
  <div class='page-chat' class:-user={selectedUser}>
    {#if Object.keys(users).length || selectedCategory}
      <ChatContainer
        {chatPage}
        {correctionMode}
        {histories}
        {iso}
        {lastPage}
        {messages}
        {selectedCategory}
        {selectedUser}
        {showOlder}
        {users}
        {vocabulary}
        bind:selectedWord
        bind:showFilters
      />
    {/if}
  </div>
</div>

<style lang='scss'>
  ._icon {
    &.-active {
      background-color: var(--Gray-Medium);
    }
  }

  @media (max-width: 768px) {
    ._icon {
      &.-active {
        background-color: inherit;
      }
    }

    :global(._breadcrumbicons .fake-button) {
      width: 100%;
    }

    .page-chat {
      &.-user {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
      }
    }
  }
</style>
